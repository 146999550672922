*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  box-sizing: border-box;
  background: #f7f8f7;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.App-header {
  border-bottom: 1px solid rgba(151, 151, 151, 0.15);
}

span.tooltip[role="tooltip"] {
  z-index: 9999;
  pointer-events: none;
}

span.tooltip[role="tooltip"] .withPointer {
  pointer-events: all;
}

.logo-link {
  line-height: 0;
}

input[type="radio" i] {
  margin: 0;
  margin-bottom: 2px;
}

#no-token-warning {
  display: none;
}
